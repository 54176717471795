import React, { useState, useEffect } from "react";
import Layout from "../../Layout";
import Union from '../../../../../content/images/Union.svg';
import { useHistory, useLocation } from "react-router-dom";
import Axios from "axios";
import { ErrorPage } from "./errorPage";


export default function StageTwo() {

    const [pickedService, setPickedService] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [selectAllTab, setSelectAllTab] = useState([]);
    const [scheme, setScheme] = useState<any>();
    const [loading, setLoading] = useState(false)
    const [services, setServices] = useState([]);
    
    const location = useLocation();
    const history = useHistory()

    

    const handlePickService = (value) =>{
        if (!pickedService.includes(value)){
        setPickedService([...pickedService, value])
        }else{
            const data = []
            const allTab=pickedService.map(e=>e!==value && data.push(e));
            setPickedService(data);
        }
    }

    const selectAll = ()=> {
        if(selectedAll){
        // setPickedService(mockServices.map(e=>e.id));
        }else{
            setSelectedAll(false);
            setPickedService([])
        }
    }
    
    const getServices = async () => {
        try {
            const schemes = await Axios.get("/api/scheme-services");
            setServices(schemes.data)
            
        } catch (error) {
            console.error(error.response);
        }
    }

    const saveServices= async (data) => {
        try {
            setLoading(true);
            const saveService = await Axios.post("/api/schemes/add-services",
             { "schemeID": scheme.id,  "regState": 2,  "services":[...data]});
            setLoading(false)
             history.push('stage-three', {id:scheme.id})

        } catch (error) {
            setLoading(false)
            if(error.response) {
                alert(error.response.data.message)
            }else {
                alert("Network error!")
            }
        }
    }
    
    const handeSelectAllTab = (value) => {
        if(!selectAllTab.includes(value)) {
        const data =[]
        const allTab = services.map((e, index)=>index===value && e.schemeServicesDTOS.map(item=>data.push(item)));
        setPickedService([...pickedService, ...data]);
        setSelectAllTab([...selectAllTab, value]);
         }
    }
   const handleSelectTab=(value:number)=>{
        if(selectedTab!== value) {
            setSelectedTab(null)
            setSelectedTab(value)
        }else{
            setSelectedTab(null)
        }
    }

    function nextPage(){
    }

    useEffect(() => {
        setScheme(location.state)
        getServices()
     }, []);


    return (
        <Layout>
        {scheme && scheme.id ?
        <div style={{ width: "100%", marginTop: '20px', padding: "20px" }}>
        <p className="main-title">Administration</p>
                <p className="bread-crumb"><img src={Union} alt="union symbol" />&nbsp; &nbsp; 
                Administration &nbsp; <span className='crumb-divider'> {'  >  '}</span> &nbsp;&nbsp; 
                Scheme Management </p>
                <br/>
            <p style={{ fontFamily: 'Poppins', fontStyle: 'normal', fontWeight: 900, fontSize: '18px' }}>
                    Scheme Services
            </p>
            <div style={{backgroundColor:'#fff', padding:'40px 50px'}}>
                {/* <div  onClick={selectAll}  className="check-box-group">
                    <span className="stage-two-title">Select All</span>
                    <input type="checkbox" checked={!selectedAll? true:false} />
                </div> */}
                { services.map((item, index)=>
                    <div key={index} >
                        <div   className="categories-group">
                            <div onClick={()=>handleSelectTab(index)} className="stage-two-title"><span >{item.category}</span> <b>&gt;</b></div>
                            <div onClick={()=>handeSelectAllTab(index)} style={{fontSize:'12px', fontWeight:600}}>Select All &nbsp;&nbsp;
                                
                            </div>
                        </div>
                        {index === selectedTab &&
                        <div style={{width:'100%', display:'flex', flexWrap:'wrap', padding:'7px 15px', justifyContent:'space-between'}}>
                            {item.schemeServicesDTOS.map(service=>
                                <div onClick={()=>handlePickService(service)} key={service.id} style={{width:'100%'}} className="check-box-group">
                                    <span className="stage-two-title">{service.name}</span>
                                    <input type="checkbox" checked={pickedService.map(e=>e.id).includes(service.id) ? true :false } />
                                </div>
                            )}
                        </div>}
                    </div>
                    )
                }

                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: '100%', margin: "15px 0px" }}>
                {/* <Link to={`stage-three`}> */}
                    <button onClick={()=>saveServices(pickedService)} disabled={loading || pickedService.length<1}  className='viewreport'> Next</button>
{/*                     
                </Link> */}
                </div>
            </div>
            
        </div>:
        <ErrorPage/>
        }
        </Layout>
    )
}