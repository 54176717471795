// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../content/images/jhipster_family_member_2.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../content/images/bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "/* ==========================================================================\nMain page styles\n========================================================================== */\n.hipster {\n  display: inline-block;\n  width: 100%;\n  height: 497px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center top;\n  background-size: contain;\n}\n\n.home-bg {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  width: 100%;\n  height: 80vh;\n  background-position: center;\n  background-repeat: no-repeat;\n  background-position: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n.home-bg .welcome-head {\n  color: #f16521;\n}\n.home-bg .welcome-paragraph {\n  color: #fff;\n}\n.home-bg .get-started-button {\n  background-color: #1ca78b;\n  border: 0px;\n  width: 150px;\n  padding: 8px;\n  border-radius: 20px;\n  color: white;\n  font-weight: bold;\n  height: 40px;\n}", ""]);
// Exports
module.exports = exports;
