import React from "react";
import {Modal} from 'reactstrap'

export function CustomModal(props) {
    return (
        <div >
            <Modal style={{position:'relative', top:'20%', left:'50px'}} backdrop={false} keyboard={true} isOpen={props.modalState}>
                <div style={{height:'100%', width:'100%', borderRadius:'10px', backgroundColor:props.background}}>
                    {props.body}
                </div>
            </Modal>
        </div>
    )
}