import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Axios from 'axios';
import Spinner from '../../../../component/spinner/Spinner';

const ChangeUserRole= ({ user }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [userRoles, setUserRoles] = useState(['ajaks','sasjkj']);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const { user: {firstName, lastName}, phoneNumber } = user;
    
    const getUserRoles = async (): Promise<void>=> {
        try {
          const res = await Axios.get(`/api/role`);
          if(res.data.code === "00") {
            const data = res.data.data.map(item => {
              return item.name!== "ADMIN_53797374656d73706563732077616c6c6574"&& {
                value: item.name,
                label: item.name.substring(5,item.name.length).replace('_',' ').toLowerCase()
              }
            })
            setUserRoles(data)
          }
        } catch (err) {
          console.error(err.response);
        }
      }
      
    const handleUChangeUser = async ():Promise<void> => {
        try {
            setLoading(true)
            const res = await Axios.put(`/api/update-user-role`,
            {"phoneNumber": user.phoneNumber, "userRole": [selectedOption?.value]})
            if(res.data.code === "00") alert(`User role successfully upgraded to ${selectedOption?.value}`)
         } catch(err) {
            console.error(err);
            alert('An error occurred while upgrading customer role, try again');
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
      getUserRoles()

    }, [])

    return (
        <div className="container__reactivate">
            <Select
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={userRoles}
                placeholder="Choose role"
              />
            {!show &&
            <button
                type="button"
                className="kyc__btn"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => setShow(true)}>Change User Role
            </button>
            }
            {show && (
                !selectedOption ?
                <div><p>Please kindly select a customer role to upgrade {firstName} {lastName} to</p></div>
                :
                <div>
                  <p>Are you sure you want to change role for {firstName} {lastName} to  {selectedOption?.value}</p>
                  <div>
                      <button onClick={() => setShow(false)}>Cancel</button>
                      <button onClick={handleUChangeUser}>{loading ? <Spinner /> : 'Upgrade'}</button>
                  </div>
                </div>
            )}
        </div>
     );
}

export default ChangeUserRole;
