import React from 'react';
import { Link} from "react-router-dom";
import {MdOutlineNoEncryptionGmailerrorred} from 'react-icons/md'


export const ErrorPage =()=>{
    return(
        <div style={{width:'100%', height:'87vh', background:'#ffff', display:'flex', flexDirection:'column',justifyContent:'center', alignItems:'center'}}>
            <p>
                <MdOutlineNoEncryptionGmailerrorred size={60} />
            </p>
            The Sceheme you picked cannot be editted 
            <div style={{ margin: "15px 0px" }}>
                <br/>
                <Link to={`stage-one`}>
                    <button  className='viewreport'> Go Back</button>               
                </Link>
                </div>
        </div>
    )
}