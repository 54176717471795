import './ReverseTransaction.scss';
import Axios from 'axios';
import React, { useState } from 'react';
import Layout from '../Layout';
import InnerLayout from '../components/Layout';


export default function ReverseTransaction(props) {

    const [transRef, setTransRef] = useState('');
    const [resdata, setResData] = useState({});
    const [loading, setLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(false);


    const reverseTransaction = async event => {
        event.preventDefault();

        const adminToken = JSON.parse(sessionStorage.getItem('jhi-authenticationToken'));


        try {
            setLoading(true);
            const res = await Axios.post(`/api/reverse/${transRef}`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${adminToken}`,
              }
            })
            setResData(res.data.data);

            if (res.data.code === '00') {
                setLoading(false);
                setShowDetails(true);
                alert('Transaction reversed successfully');
            }

        } catch (err) {
            const error = err.response?.data?.message ?? 'An error occurred, please try again'
            alert(error);
          } finally {
            setLoading(false);
          }
    }


  return (
    <>
      <Layout>
        <InnerLayout dontfilter={true} show={true} title="Reverses Transaction" path="Reverse transaction">
          <form onSubmit={reverseTransaction} className="reverseTransactionConnector">
            <input
              required={true}
              onChange={e => {
                setTransRef(e.target.value);
              }}
              value={transRef}
              type="text"
              className="textinput marg"
              placeholder="Reference of transaction"
            />

            <br />
            <button disabled={loading} type="submit" className="send-money-btn">
              {!loading ? 'Reverse' : 'loading...'}
            </button>
          </form>
        </InnerLayout>
      </Layout>
    </>
  );
}
