import './findCustomerByBvn.scss';
import Axios from 'axios';
import React, { useState } from 'react';
import Layout from '../Layout';
import InnerLayout from '../components/Layout';


export default function findCustomerByBvn(props) {

    const [customerBvn, setCustomerBvn] = useState('');
    const [data, setData] = useState({
      "bvn": "",
      "firstName": "",
      "lastName": "",
      "phoneNumber": "",
      "gender": "",
      "dob": "",
      "email": "",
    });
    const [loading, setLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(false);


    const searchBvn = async event => {
        event.preventDefault();

        const adminToken = JSON.parse(sessionStorage.getItem('jhi-authenticationToken'));


        try {
            setLoading(true);
            const res = await Axios.post(`/api/find-customer/${customerBvn}`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${adminToken}`,
              }
            })
            setData(res.data.data);

            if (res.data.code === '00') {
                setLoading(false);
                setShowDetails(true);
            }

        } catch (err) {
            const error = err.response?.data?.message ?? 'An error occurred, please try again'
            alert(error);
          } finally {
            setLoading(false);
          }
    }


  return (
    <>
      <Layout>
        <InnerLayout dontfilter={true} show={true} title="Search Customer BVN" path="Search BVN">
          <form onSubmit={searchBvn} className="findCustomerByBvnConnector">
            <input
              required={true}
              onChange={e => {
                setCustomerBvn(e.target.value);
              }}
              value={customerBvn}
              type="text"
              className="textinput marg"
              placeholder="Insert BVN of Customer"
            />

            <br />
            <button disabled={loading} type="submit" className="search-bvn-btn">
              {!loading ? 'Search' : 'loading...'}
            </button>
          </form>

          {
            showDetails && (
              <div>
              <p style={{ marginTop: '20px' }} className="titles2">
                Customer BVB Details
              </p>
              <div className="dateContainer">
                <table>
                  <thead></thead>
                  <tbody>
                    <tr className="drow">
                      <th>BVN Found: </th>
                      <td>{data.bvn}</td>
                    </tr>
                    <tr className="drow">
                      <th>First name: </th>
                      <td>{data.firstName}</td>
                    </tr>
                    <tr className="drow">
                      <th>Last name: </th>
                      <td>{data.lastName}</td>
                    </tr>
                    <tr>
                      <th>Date of birth: </th>
                      <td>{data.dob}</td>
                    </tr>
                    <tr className="drow">
                      <th>Gender: </th>
                      <td>{data.gender}</td>
                    </tr>
                    <tr className="drow">
                      <th>Phone number: </th>
                      <td>{data.phoneNumber}</td>
                    </tr>
                    <tr className="drow">
                      <th>Email: </th>
                      <td>{data.email}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            )
          }

        </InnerLayout>
      </Layout>
    </>
  );
}
