import React, { useState } from "react";
import { Modal } from "reactstrap";
import SuccessIcon from '../../../assets/images/success-icon.svg'
 
export function SuccessModal(props) {
    // const [modalState, setModalState] = useState(false);

    // const toggleModal =() => {
    //     setModalState(!modalState);
    // }

    return (
        // <div>
                <Modal style={{width:'455px', height:'437px'}} isOpen={props.modalState}  className=''>
                    <div className="icon-header">
                        <img src={SuccessIcon}/>
                    </div>

                    <p style={{fontWeight:600, display:'flex', justifyContent:'center'}}>{props.title}</p>
                    <div style={{padding:'10px 50px', color:'#3B445A', fontFamily:'RaleWay', fontSize: '16px', textAlign:'center'}}>
                        {props.message}
                    </div>
                    {props.button}
                    {/* <div onClick={props.toggle} style={{display: 'flex', justifyContent:'center', cursor:'pointer'}}>
                        <div className="modal-button">Fees</div>
                    </div> */}
                    <br/>
                </Modal>
            // </div>
    )
}