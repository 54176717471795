import './index.scss';
import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../Layout';
import Union from '../../../../../content/images/Union.svg';
import {FcEmptyTrash} from 'react-icons/fc'
import ShareButton from '../../../../assets/images/share.svg'
import { CustomModal } from 'app/component/spinner/modals/CustomModal';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';

export default function Index() {

    const history = useHistory()
    const [loading, setLoading] = useState(false);
    const [bankList, setBankList] = useState([]);
    const [productType, setProductType] = useState([]);
    const [schemeType, setSchemeType] = useState([]);
    const [viewModal, setViewModal] = useState(false);
    const [modalDetails, setModalDetails] = useState(null);
    const [schemeData, setSchemeData] = useState(null);
    const [schemeRequest, setSchemeRequest] = useState({  
        "scheme": '',
        "accountNumber": "",
        "bankCode": "",
        "productType": "",
        "schemeType": {
          "name": ""
        }
      }
)
    const [schemeList, setSchemeList] = useState([])
    const isMounted = useRef(false);
        const toggleModal=(data)=> {
            setModalDetails(data);
            setViewModal(!viewModal);

        }

        
        function ModalDetails (){
            return <div style={{padding:'30px'}}>
                    	<div style={{color:'#fff', display:'flex', justifyContent:'flex-end'}}>
                            <div onClick={toggleModal} style={{cursor:'pointer'}}>&#x2715;</div>
                        </div>
                        <div className='details-row underline-text'>Scheme Name: {modalDetails?.scheme}</div>
                        <div className='details-row underline-text'>Operational account 1 {`(${modalDetails?.accountOne})`} - {modalDetails?.operationalOneAmount}</div>
                        <div className='details-row underline-text'>Operational account 2 {`(${modalDetails?.accountTwo})`} - {modalDetails?.operationalTwoAmount}</div>
                        <div className='details-row'>VAT {`(${modalDetails?.vatAccount})`} - {
                            <NumberFormat  displayType="text"
                                thousandSeparator={true}
                                fixedDecimalScale={true} 
                                value={modalDetails?.vatAmount} 
                            /> }</div>
                            <div className='button-container'>
                                <div onClick={()=>history.push(modalDetails.regStage===1 ?'stage-two':(modalDetails.regStage===2?'stage-three':'#'),{id:modalDetails?.schemeID})} className='edit-scheme-button'>Edit Scheme</div>
                            </div>
                    </div>;
        }
        
        const getScheme = async () => {
            try {
                const schemes = await Axios.get("/api/schemes");
                setSchemeData(schemes?.data);
                setSchemeList(schemes?.data)
              
            } catch (error) {
                console.error(error.response);
            }
        }

        const getProductType= async()=> {
            try{
                const productTypes = await Axios.get("/api/schemes/product-type");
                setProductType(productTypes?.data?.data);
            
            }catch(error){
                console.error(error.response);
            }
        } 

        
        const getSchemeTypes = async()=>{
            try{
                const schemeTypes = await Axios.get("/api/scheme-types");
                setSchemeType(schemeTypes?.data)
            }catch(error){
                console.error(error.response);
            }
        }

        const getBanksList =async ()=>{
            try{
                 const banks=await Axios.get('/api/banks/commercial');
                 setBankList(banks?.data?.data);
            }catch (error){
                console.error(error.response);

            }
        }

        const createSheme = async ()=>{
            try{
                setLoading(true)
                const saveScheme = await Axios.post("http://192.168.19.59:8084/api/schemes",{...schemeRequest});
                if(saveScheme.status===201 || saveScheme.status===200 ){
                    setLoading(false)
                    history.push('stage-two',{id:saveScheme.data.data.schemeID})
                }
            }catch(error){
                setLoading(false)
                if(error.response) {
                    alert(error.response.data.message)
                }else {
                    alert("Network error!")
                }
            }
        }


    useEffect(() => {
        getScheme()
        getProductType()
        getSchemeTypes()
        getBanksList()
    }, []);

    const handleChange = event => {
        const result = event.target.value.replace(/\D/g, '');
        setSchemeRequest({...schemeRequest, 'accountNumber':result});
      };

    return (
        <Layout>
            <div style={{ width: "100%", padding: "20px" }}>
                <p className="main-title">Administration</p>
                <p className="bread-crumb"><img src={Union} alt="union symbol" />&nbsp; &nbsp; Administration &nbsp; <span className='crumb-divider'> {'  >  '}</span> &nbsp;&nbsp; Scheme Management </p>
                <br/>
                <CustomModal modalState={viewModal} background='#435FAA' body={<ModalDetails/>} />

                <div className='form-container'>
                    <div>
                        <div style={{ margin: "12px 0px" }} className="admin-input-label">Enter Scheme Name</div>
                        <input className="admin-text-input" onChange={(e)=>setSchemeRequest({...schemeRequest, 'scheme':e.target.value})} placeholder='Enter Scheme Name' type="text" />
                    </div>

                    <div>
                        <div style={{ margin: "12px 0px" }} className="admin-input-label">Enter Pool Bank</div>
                        <select onChange={(e)=>setSchemeRequest({...schemeRequest, 'bankCode':e.target.value})} className="admin-text-input">
                            <option value={null}>Select Bank Name</option>
                            {bankList.length>0 ? bankList.map(item =>
                                <option key={item.bankCode} value={item.bankCode}>{item.bankName}</option>
                            ):''}
                        </select>
                    </div>

                    <div >
                        <div style={{ margin: "12px 0px" }} className="admin-input-label">Scheme Pool Account Number</div>
                        <input value={schemeRequest.accountNumber} onChange={handleChange} className="admin-text-input" 
                        placeholder='Scheme Pool Account Number' type="text" maxLength={10}  />
                    </div>

                    <div>
                        <div style={{ margin: "12px 0px" }} className="admin-input-label">Scheme Type</div>
                        <select onChange={(e)=>setSchemeRequest({...schemeRequest, 'schemeType':{ ...schemeData.schemeType, 'name': e.target.value }})} className="admin-text-input">
                            <option value={null}>Select Scheme Type</option>
                            {schemeType?.length>0 && schemeType?.map(item =>
                                <option key={item.id} value={item.name}>{item.name}</option>
                            )}
                        </select>
                    </div>

                    <div>
                        <div style={{ margin: "12px 0px" }} className="admin-input-label">Product Type</div>
                        <select onChange={(e)=>setSchemeRequest({...schemeRequest, 'productType':e.target.value })} className="admin-text-input">
                            <option value={null}>Select Product Type</option>
                            {productType?.length>0 &&productType?.map(item =>
                                <option key={item} value={item}>{item}</option>
                            )}
                        </select>
                    </div>

                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: '100%', margin: "15px 0px" }}>
                        {/* <Link to={`stage-two`}>   */}
                            <button  onClick={createSheme} disabled={loading || !schemeRequest.accountNumber || !schemeRequest.bankCode ||
                             !schemeRequest.productType||!schemeRequest.schemeType.name||!schemeRequest.scheme} 
                            className='viewreport'>{loading ? "Loading..." : "Next"}</button>
                        {/* </Link> */}
                    </div>
                </div>

                <p style={{ fontFamily: 'Poppins', fontStyle: 'normal', fontWeight: 900, fontSize: '18px', marginTop: '30px' }}>Scheme</p>
                <div className="table-wrapper" style={{ width: "90%", margin: '15px 0px', backgroundColor: '#fff', padding: '24px', borderRadius: '12px' }}>
                    <table style={{ width: "100%", border: 'none' }}>
                        <thead style={{ fontWeight: 700, fontSize: '12px', padding:'10px 0px' }}>
                            <th style={{ padding:'10px 0px' }}>S/N</th>
                            <th style={{ height: '45px', padding:'10px 20px', margin: '8px 0px'}}>Scheme Name</th>
                            <th style={{ height: '45px', padding:'10px 20px', margin: '8px 0px'}}>Scheme Pool Bank</th>
                            <th style={{ height: '45px', padding:'10px 20px', margin: '8px 0px'}}>Scheme Pool Account</th>
                            <th style={{ height: '45px', padding:'10px 20px', margin: '8px 0px'}}>Scheme Type</th>
                            <th style={{ height: '45px', padding:'10px 20px', margin: '8px 0px'}}>Reg. State</th>
                        </thead>
                        <tbody style={{ fontFamily: 'Raleway', fontStyle: 'normal', fontWeight: 500, fontSize: '13px' }} className="admin-table-body">
                            {schemeList?.length>0 ? schemeList.map((item, index) =>
                                <tr key={index} style={{ border: 'none', }}>
                                    <td ><div style={{ backgroundColor: '#F2F5FB', height: '45px', padding:'10px 20px', margin: '8px 0px', border: 'none' }}>{index + 1}</div></td>
                                    <td ><div style={{ backgroundColor: '#F2F5FB', height: '45px', padding:'10px 20px', margin: '8px 0px', border: 'none' }}>{item?.scheme}</div></td>
                                    <td><div style={{ backgroundColor: '#F2F5FB', height: '45px', padding:'10px 20px', margin: '8px 0px', border: 'none' }}>{bankList?.map(e=>e.bankCode===item?.bankCode? e.bankName:'')}</div></td>
                                    <td><div style={{ backgroundColor: '#F2F5FB', height: '45px', padding:'10px 20px', margin: '8px 0px', border: 'none' }}>{item?.accountNumber}</div></td>
                                    <td><div style={{ backgroundColor: '#F2F5FB', height: '45px', padding:'10px 20px', margin: '8px 0px', border: 'none' }}>{item?.schemeType?.name}</div></td>
                                    <td><div style={{ backgroundColor: '#F2F5FB', height: '45px', padding:'10px 20px', margin: '8px 0px', border: 'none' }}>{item?.regStage}</div></td>
                                    <td><div onClick={()=>toggleModal(item)} style={{ backgroundColor: '#F2F5FB', height: '45px', padding:'10px 20px', margin: '8px 0px', border: 'none', cursor:'pointer' }}>
                                        <img style={{paddingRight:'5px'}} src={ShareButton}/>View Scheme</div></td>
                                    
                                </tr>
                               
                            ):<tr>
                                <td style={{backgroundColor:'#fff', padding:'50px'}} colSpan={6}>
                                        <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', fontSize:'20px', color:'rgba(0, 0, 0, 0.3)' }}>
                                            <FcEmptyTrash color='gray' size={80} />
                                                No data Found
                                        </div>
                                </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </Layout>
    )
}