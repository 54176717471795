import './index.scss';
import React, { useEffect, useState } from 'react';
import Layout from '../../Layout';
import Union from '../../../../../content/images/Union.svg';
import { SuccessModal } from 'app/component/spinner/modals/SuccessModal';
import Axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { ErrorPage } from './errorPage';


export default function StageThree(props) {

    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const [modalState, setModalState] = useState(false);
    const [scheme, setScheme] = useState<any>()
    const [selectedImage, setSelectedImage] = useState({image:''});
    const [formData, setFormData] = useState({firstName:'',lastName:'', phoneNumber:'', email:'', role:'',schemeID: '',  })
    const [schemeList, setSchemeList] = useState([
        { name: 'Systemspecs', bank: 'Polaris', account: '012324434', type: 'Communites' },
        { name: 'Systemspecs', bank: 'Polaris', account: '012324434', type: 'Schools' },
        { name: 'Systemspecs', bank: 'Polaris', account: '012324434', type: 'Schools' },
        { name: 'Systemspecs', bank: 'Polaris', account: '012324434', type: 'Communites' },])

    const location = useLocation();
    const history = useHistory();
    
    const toggleModal = () => {
        setModalState(!modalState)
    }

    const getRoles = async (id)=> {
        try {
            const roleList = await Axios.get(`/api/schemes/roles?schemeID=${id}`);
            setRoles(roleList?.data.data)
        } catch (error) {
            console.error(error.response);
        }
    }
    const createAdmin= async(id) =>{
        try {
            setLoading(true)
            setFormData({...formData, 'schemeID':id})
            const saveService = await Axios.post("/api/schemes/create-admin", formData);
            if(saveService.data.code==='00'){
                setLoading(false)
                setModalState(true)
            }
            
        } catch (error) {
            setLoading(false)
            if(error.response) {
                alert(error.response.data.message)
            }else {
                alert("Network error!")
            }
        }
    }

    useEffect( () => {
        setScheme(location.state);
        setFormData({...formData, 'schemeID':scheme?.id})
        getRoles(scheme?.id)
    }, [scheme]);

    const handleDone =()=> {
        history.push('stage-one')
        toggleModal;
    }

    function Welcome() {
        return <div onClick={handleDone} style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
            <div className="modal-button">Done</div>
        </div>;
    }

    const handleChange = event => {
        const result = event.target.value.replace(/\D/g, '');
        setFormData({...formData, 'phoneNumber':result});
      };


    return (
        <Layout>
            {scheme && scheme.id ?
            <div style={{ width: "100%", marginTop: '20px', padding: "20px" }}>
                <p className="main-title">Administration</p>
                <p className="bread-crumb"><img src={Union} alt="union symbol" />&nbsp; &nbsp; Administration &nbsp; <span className='crumb-divider'> {'  >  '}</span> &nbsp;&nbsp; Scheme Management </p>
                <p style={{ fontFamily: 'Poppins', fontStyle: 'normal', fontWeight: 900, fontSize: '18px', marginTop: '30px' }}>
                    Admin Details
                </p>
                <div className='form-container'>
                    <div>
                        <div style={{ margin: "12px 0px" }} className="admin-input-label">Enter First Name</div>
                        <input onChange={(e)=>setFormData({...formData, 'firstName':e.target.value})} className="admin-text-input" placeholder='Enter Admin First Name' type="text" />
                    </div>

                    <div>
                        <div style={{ margin: "12px 0px" }} className="admin-input-label">Enter Last Name</div>
                        <input onChange={(e)=>setFormData({...formData, 'lastName':e.target.value})} className="admin-text-input" placeholder='Enter Admin Last Name' type="text" />
                    </div>

                    <div >
                        <div style={{ margin: "12px 0px" }} className="admin-input-label">Admin Phone Number</div>
                        <input value={formData.phoneNumber} onChange={handleChange} maxLength={11} className="admin-text-input" placeholder='Admin Phone Number' type="text" />
                    </div>

                    <div >
                        
                        <div style={{ margin: "12px 0px" }} className="admin-input-label">Admin Email</div>
                        <input onChange={(e)=>setFormData({...formData, 'email':e.target.value})} className="admin-text-input" placeholder='Admin Phone Email' type="email" />
                    </div>

                    <div>
                        <div style={{ margin: "12px 0px" }} className="admin-input-label">Select Admin Role</div>
                        <select onChange={(e)=>setFormData({...formData, 'role':e.target.value})} className="admin-text-input">
                            <option>Select Admin Role</option>
                            {roles.length>0 && roles?.map(item =>
                                <option key={item} value={item}>{item}</option>
                            )}
                        </select>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: '100%', margin: "15px 0px" }}>
                        <button onClick={()=>createAdmin(scheme.id)} disabled={loading || !formData.email || !formData.firstName
                        || !formData.lastName || !formData.phoneNumber || !formData.role || !formData.schemeID} className='viewreport'>{loading ? "Loading..." : "Create Scheme"}</button>
                    </div>
                </div>
                <SuccessModal
                    message='Scheme has been created successfully Please go to Fees menu to configure fees'
                    modalState={modalState} button={<Welcome />} title='Successful'
                />
            </div>
            : <ErrorPage/>}
        </Layout>
    )
}